import React from 'react'
import classes from "./Loading.module.css";

const LoadingBig = () => {
  return (
    <div className={classes.container}>
      <div className={classes.loader_2}></div>
    </div>
  )
}

export default LoadingBig