import React, { useEffect, useRef, useState } from "react";
import "../Css/login.css";
// import img_logo from "../Images/bigcash-logo.png";
import Select from "react-select";
import { changeCurrency, price, subscribeUser } from "../Data/data";
import Get from "../Api/Get";
// import { toast, ToastContainer } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
// import {loginUser} from   '../Data/data';
// import Post from '../Api/Post';
import { useNavigate } from "react-router-dom";
import Post from "../Api/Post";
import classes from "../Css/Subscription.module.css";
import Header from "../Components/Header";
import footer_logo from "../Images/logomain.png";
import Loading from "../Components/Loading";
import Modal from "../Components/Modal";
import axios from "axios";
import Loader from "../Components/Loader";
import LoadingBig from "../Components/LoadingBig";

const Subscription = () => {
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [currencyPackLoading, setCurrencyPackLoading] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const [selectedPack1, setSelectedPack1] = useState(null);
  const [selectedPack2, setSelectedPack2] = useState(null);

  const currencyOptions = [
    { value: "USD", label: "United States Dollar (USD)" },
    { value: "ZWG", label: "Zimbabwe Gold (ZWD)" },
  ];

  const [packOptions1, setPackOption1] = useState([]);
  const [packOptions2, setPackOption2] = useState([]);

  // const packOptions2 = [
  //   {
  //     value: "Daily",
  //     label: "Daily ZWG2.31",
  //   },
  //   {
  //     value: "Weekly",
  //     label: "Weekly ZWG9.24",
  //   },
  //   {
  //     value: "Monthly",
  //     label: "Monthly ZWG27.73",
  //   },
  // ];

  // const packOptions1 = [
  //   {
  //     value: "Daily",
  //     label: "Daily USD 0.05",
  //   },
  //   {
  //     value: "Weekly",
  //     label: "Weekly USD 0.10",
  //   },
  //   {
  //     value: "Monthly",
  //     label: "Monthly USD 0.40",
  //   },
  // ];

  const handlePackChange1 = (option) => {
    setSelectedPack1(option);
  };

  const handlePackChange2 = (option) => {
    setSelectedPack2(option);
  };

  const handleCurrencyChange = (selectedOption) => {
    if (mobileNumber.trim().length === 0) {
      inputRef.current.focus();
      return;
    }
    setSelectedCurrency(selectedOption);
    fetchCurrenyPacks(selectedOption?.value);
  };

  const fetchCurrenyPacks = async (currencyValue) => {
    try {
      setCurrencyPackLoading(true);
      const response = await axios.get(`${price}?currency=${currencyValue}`);
      setCurrencyPackLoading(false);
      if (currencyValue == "USD") {
        setPackOption1(
          response?.data?.map((dataItem) => {
            return {
              value: dataItem?.service,
              label: dataItem?.currency + " " + "$" + dataItem?.price,
            };
          })
        );
      } else {
        setPackOption2(
          response?.data?.map((dataItem) => {
            return {
              value: dataItem?.service,
              label: dataItem?.currency + " " + dataItem?.price,
            };
          })
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          error?.data ||
          error?.message ||
          error
      );
    }
  };

  const handleMobileChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleSubscribe = async (e) => {
    if (
      !selectedCurrency ||
      selectedCurrency.value === "" ||
      selectedCurrency.value === undefined ||
      !mobileNumber ||
      mobileNumber.trim() === "" ||
      mobileNumber.trim() === undefined
    ) {
      // Show toast notification for incomplete input
      toast.error("Please fill all fields");
      return;
    }

    if (selectedCurrency === "USD") {
      if (selectedPack1.value === "" || selectedPack1.value === undefined) {
        toast.error("Please select the package!");
        return;
      }
    }

    if (selectedCurrency === "ZWG") {
      if (selectedPack2.value === "" || selectedPack2.value === undefined) {
        toast.error("Please select the package!");
        return;
      }
    }

    // Log the selected pack
    // console.log("Selected Pack:", selectedOption.value);

    // Log the mobile number
    //console.log("Mobile Number:", mobileNumber);
    localStorage.setItem("ani", mobileNumber);

    hitforSubscriberuser(
      mobileNumber,
      selectedCurrency.value,
      selectedPack1?.value,
      selectedPack2?.value
    );
    // Clear the states after handling the login action
    setMobileNumber(null);
    // setSelectedOption(null);
  };

  const hitforSubscriberuser = (mobileNumber, currency, packUSD, packZIM) => {
    // console.log("MobNo:", mobileNumber);

    let request = {
      ani: mobileNumber,
      currency: currency,
      pack: currency === "USD" ? packUSD : packZIM,
    };
    //console.log("link", subscribeUser);
    setLoading(true);
    let promise = Post(subscribeUser, request);
    promise.then((e) => {
      console.log("e ", e);
      handlingResponse(e);
    });
    // handlingResponse(promise)
  };

  const handlingResponse = (response) => {
    console.log("trs", response);
    // if (e === 1) {
    //   setLoading(false);

    //   toast.error("Billing Pending");
    //   return;
    // } else if (e === 2) {
    //   setTimeout(() => {
    //     setLoading(false);
    //     navigate("/otp");
    //   }, 1000);
    // } else if (e === "Network Error") {
    //   setLoading(false);

    //   //Backend Not Working - so sending to error page
    //   navigate("/error");
    // } else if (e === 0) {
    //   toast.error("Billing Pending");
    //   return;
    // } else if (e === 3) {
    //   setTimeout(() => {
    //     setLoading(false);
    //     navigate("/otp");
    //   }, 1000);
    // } else {
    //   toast.error("Billing Pending");
    //   return;
    // }
    setLoading(false);

    // Extract status code and message from the response
    const { status, data } = response;

    if (status === 200) {
      // Success, OTP sent
      localStorage.setItem("currency", selectedCurrency.value);
      toast.success("otp Verfied"); // Show success message (OTP sent, subscription active, etc.)
      setTimeout(() => {
        navigate("/otp");
      }, 1000);
    } else if (status === 403) {
      // No active subscription found
      toast.error(data); // Show error message that subscription is required
      return;
    } else if (status === 402) {
      // Billing failed
      toast.error(data); // Show billing failure message
      return;
    } else if (status === 404) {
      // User not found
      toast.error(data); // Show user not found message
      return;
    } else if (status === 409) {
      let optionUserIsSubscribed = currencyOptions.filter(
        (data) => data?.value != selectedCurrency?.value
      );
      setMessage({
        state: true,
        switchTo: selectedCurrency?.value,
        stayWith:
          optionUserIsSubscribed.length > 0 && optionUserIsSubscribed[0]?.value,
        msisdn: mobileNumber,
      });
      return;
    } else if (data === "Network Error") {
      // Backend Not Working - Navigate to error page
      navigate("/error");
    } else {
      // Catch all for unexpected errors
      toast.error("An unexpected error occurred.");
      return;
    }
  };

  const handleReset = () => {
    setMobileNumber(null);
    setSelectedCurrency(null);
    setSelectedPack1(null);
    setSelectedPack2(null);
  };

  const [message, setMessage] = useState({
    state: false,
    switchTo: "",
    stayWith: "",
    msisdn: "",
  });

  const handleMessageReset = () => {
    setMessage({
      state: false,
      switchTo: "",
      stayWith: "",
      msisdn: "",
    });
  };

  const handleCurrencySwitch = async () => {
    console.log(message.switchTo);
    let request = { ani: message.msisdn, currency: message.switchTo };
    const response = await Post(changeCurrency, request);
    console.log("response: ", response);
    handlingResponseforChange(response);
  };

  const handlingResponseforChange = (response) => {
    console.log("response: ", response);
    const { status, data } = response; // Destructure response status and data

    console.log("response..", status);
    console.log("data..", data);

    switch (status) {
      case 200:
      case 202: // OK
        localStorage.removeItem("ani");
        localStorage.removeItem("currency");
        toast.success("Currency Changed successfully");
        setMessage({
          state: false,
        });

        // hitOnBackendForCheckUser(message.msisdn, message.switchTo);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
        break;
      case 500: // Internal Server Error (or other server errors)
      case "Network Error": // Network Error case
        setLoading(false);
        toast.error("Network Error. Please try again later.");
        navigate("/error");
        break;
      case 409: // Network Error case
        setLoading(false);
        let optionUserIsSubscribed = currencyOptions.filter(
          (data) => data?.value != selectedCurrency?.value
        );
        setMessage({
          state: true,
          switchTo: selectedCurrency?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: mobileNumber,
        });
        // toast.error(data);
        // setOpenModal(true);
        break;

      case 404: // Not Found
        setLoading(false);
        setOpenModal(true);
        break;

      case 402: // Charging Failed
        console.log("402----", status);
        setLoading(false);
        toast.error(data);
        // Redirect to login after 2 seconds
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        break;

      default:
        setLoading(false);

        break;
    }
  };

  const handleCurrencyStay = async () => {
    toast.error(`You are already subscribed with ${message.stayWith}`);
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  return (
    <>
      {/* <div className="screen-1">
    <img className="logo" src={img_logo} alt="Logo" /> */}
      <Header tab="tab2">
        <div className={classes.form_container}>
          {message?.state == true ? (
            <>
              <div className={classes.back_btn} onClick={handleMessageReset}>
                &#8617;
              </div>
              <div className={classes.wrapper}>
                <p className={classes.message_primary}>
                  YOU ARE ALREADY SUBSCRIBED WITH {message.stayWith}
                  <p className={classes.message_secondary}>DO YOU WISH TO:</p>
                </p>
                <button
                  type="button"
                  className={classes.switch_btn}
                  disabled={loading}
                  onClick={handleCurrencySwitch}
                >
                  {loading ? <Loading /> : `SWITCH TO ${message.switchTo}`}
                </button>
                <button
                  type="button"
                  className={classes.stay_with_btn}
                  disabled={loading}
                  onClick={handleCurrencyStay}
                >
                  {loading ? <Loading /> : `STAY WITH ${message.stayWith}`}
                </button>
              </div>
            </>
          ) : selectedCurrency != null ? (
            <>
              <div className={classes.back_btn} onClick={handleReset}>
                &#8617;
              </div>
              {currencyPackLoading ? (
                <LoadingBig />
              ) : (
                <>
                  <div className={classes.dropdown_container_2}>
                    <Select
                      className={classes.selector}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: "#00063f",
                          color: "white",
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: "white", // Change the color of the text inside the box
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          color: "white", // Change the color of the placeholder text
                        }),
                      }}
                      value={
                        selectedCurrency.value === "USD"
                          ? selectedPack1
                          : selectedPack2
                      }
                      onChange={
                        selectedCurrency.value === "USD"
                          ? handlePackChange1
                          : handlePackChange2
                      }
                      options={
                        selectedCurrency.value === "USD"
                          ? packOptions1
                          : packOptions2
                      }
                      placeholder="Select Package"
                      required
                      defaultMenuIsOpen={true}
                      menuIsOpen={true}
                    />
                  </div>

                  <button
                    type="submit"
                    style={{ marginTop: "130px" }}
                    onClick={handleSubscribe}
                    className={classes.btn}
                    disabled={loading}
                  >
                    {loading ? <Loading /> : "Subscribe"}
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <div className={classes.input_container}>
                <p>+263</p>
                <input
                  ref={inputRef}
                  className={classes.input}
                  type="number"
                  name="number"
                  onChange={handleMobileChange}
                  placeholder="Enter your mobile number"
                />
              </div>
              <div
                className={classes.dropdown_container}
                onClick={() => {
                  if (mobileNumber === "") {
                    toast.error("Please Enter the number first!");
                  }
                }}
                // style={{ opacity: mobileNumber != "" ? 1 : 0.90 }}
              >
                <Select
                  className={classes.selector}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#00063f",
                      color: "white",
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: "white", // Change the color of the text inside the box
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: "white", // Change the color of the placeholder text
                    }),
                  }}
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  options={currencyOptions}
                  placeholder="Select Currency"
                  required
                  defaultMenuIsOpen={true}
                  menuIsOpen={true}
                />
              </div>
            </>
          )}
        </div>

        <div className={classes.footer_container}>
          <img src={footer_logo} alt="econet" className={classes.footer_logo} />
          <p>
            By clicking <b>login</b>, you have read, understood and agreed to be
            bound by the YoGamezPro
            <b> Terms & Conditions</b> for the service.
          </p>
        </div>
      </Header>

      {/* <ToastContainer /> */}
      <Toaster
        position="top-center"
        style={{
          marginTop: "5em", // Adjust the margin to center the notification vertically
        }}
      />

      <Modal open={openModal} closeHandler={closeModal} />
    </>
  );
};

export default Subscription;
